import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../Contex/AuthContext";
import {
  useGetWalletQuery,
  useJoinBothMutation,
  useJoinSurvivourMutation,
  useJoinTeamMutation,
} from "../../../../Store/api/ApiSlice";
import PaymentPopup from "../PaymentPopup";
import "../popup.css";
import { toast } from "react-toastify";
const CompeteInfoTeam = ({ setView, view, competSection, data }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [teamName, setteamName] = useState("");
  const [showPayment, setShowPayment] = useState(false);
  const [roomId, setRoomId] = useState("");
  const [roomIdError, setRoomIdError] = useState("");
  const handleteamName = (e) => setteamName(e.target.value);
  const [allowRandomJoin, setAllowRandomJoin] = useState(null);
  const [paymenttext, setPaymentText] = useState("");
  const [addWaitingList, setAddWaitingList] = useState(false);
  const [walletAmount, setWalletAmount] = useState(0);
  const [JoinTeamHandler, { isSuccess: joinTeamSuccess }] =
    useJoinTeamMutation();
  const [JoinSurvivourHandler, { isSuccess: joinSurvivourSuccess }] =
    useJoinSurvivourMutation();
  const [JoinbothHandler, { isSuccess: joinBothSuccess }] =
    useJoinBothMutation();

  const {
    data: walletData,
    isSuccess: getWalletSuccess,
    refetch,
  } = useGetWalletQuery();
  useEffect(() => {
    getAmount();
  }, []);
  const getAmount = () => {
    let finalAmount =
      walletData?.data?.amount + walletData?.data?.referralAmount;
    finalAmount = (finalAmount - walletData?.data?.withdrawalAmount).toFixed(2);
    setWalletAmount(finalAmount);
  };
  const navigation = useNavigate();
  const onSubmit = (type) => {
    const body = {
      eventId: data?._id,
      teamOption: "joinTeamWithRoomId",
      teamRoomId: roomId,
    };
    const body2 = {
      eventId: data?._id,
      teamOption: "joinRandomTeam",
    };
    JoinTeamHandler(type == 1 ? body : body2)
    .unwrap()
    .then((res) => {
      if (res?.data) {
        navigation("/confirmationPage", { state: res?.data });
      } 
      else {
        if (res?.error?.status == 400) {
          setShowPayment(true);
          setPaymentText(res?.error?.data?.message);
          toast.error(res?.error?.data?.message ?? "Something went wrong!");
        }
        refetch();
      }
    })
    .catch((error) => {
      toast.error(error?.data?.message ?? "Something went wrong!");
    });
  };
  const createTeam = () => {
    const createTeamBody = {
      eventId: data?._id,
      teamOption: "createTeam",
      teamData: {
        name: teamName,
        allowRandomJoin: allowRandomJoin,
        addWaitingList: false,
      },
    };
    if (view === 3) {
      JoinbothHandler(createTeamBody)
        .then((res) => {
          if (res?.data) {
            navigation("/confirmationPage", { state: res?.data });
          } else {
            if (res?.error?.status == 400) {
              setShowPayment(true);
              setPaymentText(res?.error?.data?.message);
              toast.error(res?.error?.data?.message ?? "Something went wrong!");
            }
            refetch();
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message ?? "Something went wrong!");
        });
    } else {
      JoinTeamHandler(createTeamBody)
        .unwrap()
        .then((res) => {
          if (res?.data) {
            navigation("/confirmationPage", { state: res?.data });
          } else {
            setShowPayment(true);
            setPaymentText(res?.error?.data?.message);
            toast.error(res?.error?.data?.message ?? "Something went wrong!");
            refetch();
          }
        })
        .catch((error) => {
          toast.error(error?.data?.message ?? "Something went wrong!");
        });
    }
  };
  const joinSurvivour = () => {
    JoinSurvivourHandler({
      eventId: data?._id,
    }).unwrap()
      .then((res) => {
        if (res?.data) {
          navigation("/confirmationPage", { state: res?.data });
        }
        else {
         toast.error(res?.error?.data?.message ?? "Something went wrong!");
          refetch();
        }
      })
      .catch((error) => {
        toast.error(error?.data?.message ?? "Something went wrong!");
      });
  };
  const handleRoomIdChange = (e) => {
    const value = e.target.value;
    setRoomId(value);
  
    // Validate if the Room ID is exactly 10 characters long
    if (value.length !== 10) {
      setRoomIdError("Room ID must be exactly 10 characters long.");
    } else {
      setRoomIdError(""); // Clear the error if the validation passes
    }
  };
  const renderContent = () => {
    if (view === 1 || view === 3) {
      return (
        <>
          <div className="d-block gap-4 m-1 justify-center lightGreen">
            {/* <h3 className="text-[#f1aa34] mb-2 font-semibold text-center">
            Select Option For Start
          </h3> */}
            {/* <h5 className="text-[#f1aa34]">You’re joining competition name as a Survivor.</h5> */}
            <div
              className="mb-2 !bg-[#08183b] w-100 text-start !border-[#295392] competetionInfoBtns"
              onClick={() => setSelectedOption("createTeam")}
              style={{ cursor: "pointer" }}
            >
              <Button className="!bg-[#08183b] px-2 fw-700 pb-0 border-0 mb-0">
                Create Team
              </Button>

              <p className="px-2 pb-2 fw-400 text-[#f1aa34]">
                Start your Own Team and Invite friends
              </p>
            </div>
            <div
              className="mb-2 !bg-[#08183b] w-100 text-start !border-[#295392] competetionInfoBtns"
              onClick={() => setSelectedOption("enterTeamWithRoomID")}
              style={{ cursor: "pointer" }}
            >
              <Button className="!bg-[#08183b] px-2 fw-700 pb-0 border-0 mb-0">
                Join Team With Room ID
              </Button>
              <p className="px-2 pb-2 fw-400 text-[#f1aa34]">
                Join Team with ID# sent to you
              </p>
            </div>
            <div
              className="mb-2 !bg-[#08183b] w-100 text-start !border-[#295392] competetionInfoBtns"
              onClick={() => setSelectedOption("randomTeam")}
              style={{ cursor: "pointer" }}
            >
              <Button className="!bg-[#08183b] px-2 fw-700 pb-0 border-0 mb-0">
                Join Random Team
              </Button>
              <p className="px-2 pb-2 fw-400 text-[#f1aa34]">
                You’ll join any team with a Open Roster
              </p>

              
            </div>
           
          </div>
          {/* <h3 className="text-white font-semibold text-center mt-5">
            Select Option For Start
          </h3> */}

          {selectedOption === "createTeam" && (
            <div className="">
              <Form.Group controlid="teamName">
                <div className="d-lg-block d-flex justify-between align-items-center w-100 p-1">
                  <div>
                    <Form.Label className="popupLabels mb-1 text-center team_mem  fw-600 text-white">
                      Team Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter team name"
                      onChange={handleteamName}
                      className="team_mem"
                    />
                  </div>

                  <div>
                    <Form.Label className="popupLabels mb-1  fw-600 text-white">
                      Let Anyone Join?
                    </Form.Label>
                    {/* <Form.Check
                type="checkbox"
                onChange={() => setAllowRandomJoin(!allowRandomJoin)}
                label="Yes / NO"
                className="mb-0  px-1 gold fw-600"
                style={{borderRadius:"10px"}}
              /> */}
                    <div className="d-block text-center gap-3">
                      <button
                        className={`popUpLogBtn p-2 yes_check ${
                          allowRandomJoin === true ? "selected" : ""
                        }`}
                        onClick={() => setAllowRandomJoin(true)}
                      >
                        Yes
                      </button>
                      <button
                        className={`popUpLogBtn ms-2 p-2 yes_check ${
                          allowRandomJoin === false ? "selected" : ""
                        }`}
                        onClick={() => setAllowRandomJoin(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </Form.Group>

              {allowRandomJoin && (
                <Form.Check
                  onChange={() => setAddWaitingList(!addWaitingList)}
                  type="checkbox"
                  label="Have Waiting Room"
                  className=" text-white"
                />
              )}
              <h5 className="text-[#f1aa34] text-center mt-4">
                You’re joining{" "}
                <span className="text-[#b624da]">{data?.title}</span> as a
                Teammate {view === 3 && "and Survivor"} 
              </h5>
              <div className="w-100 pt-1 d-flex flex-col items-center">
                <Button
                  variant="primary"
                  type="submit"
                  className="w-100 fw-700 fs-6 popUpLogBtn"
                  onClick={createTeam}
                >
                  Join
                </Button>
              </div>
            </div>
          )}

          {selectedOption === "enterTeamWithRoomID" && (
            <div className="">
              <Form.Group controlid="roomID">
                <Form.Label className="popupLabels mb-1  fw-600 text-white">
                  Room ID
                </Form.Label>
                <div className="w-100">
                  <Form.Control
                    onChange={handleRoomIdChange}
                    type="text"
                    placeholder="Enter room ID"
                  />
                </div>
                {roomIdError && (
                  <div className="text-danger mt-2">
                    {roomIdError}
                  </div>
                )}
              </Form.Group>
              <div className="w-100 pt-1 d-flex flex-col items-center">
              <h5 className="text-[#f1aa34] text-center mt-2">
                You’re joining{" "}
                <span className="text-[#b624da]">{data?.title}</span> as a
                Teammate {view === 3 && "and Survivor"} 
              </h5>
                <Button
                  onClick={() => {
                    onSubmit(1);
                  }}
                  variant="primary"
                  type="submit"
                  className="w-100 mt-1 fw-700 fs-6 popUpLogBtn"
                >
                  Join
                </Button>
              </div>
            </div>
          )}

          {selectedOption === "randomTeam" && (
            <div className="w-100 pt-3 mt-2 d-flex flex-col items-center">
               <h5 className="text-[#f1aa34] text-center mt-2">
                You’re joining{" "}
                <span className="text-[#b624da]">{data?.title}</span> as a
                Teammate {view === 3 && "and Survivor"} 
              </h5>
              <Button
                onClick={() => {
                  onSubmit(2);
                }}
                variant="primary"
                type="submit"
                className="w-60 fw-700 fs-6 popUpLogBtn"
              >
                Join
              </Button>
            </div>
          )}
        </>
      );
    } else if (view === 2) {
      return (
        <>
          <div className="mt-3 d-flex flex-col p-0 gap-2 items-center text-[#f1aa34]">
            {/* <h5>Do you want to join the Survivor competition?</h5> */}
            <h5 className="text-center">
              You’re joining{" "}
              <span className="text-[#b624da]">{data?.title}</span> as a
              Survivor.
            </h5>
            <div className="flex w-100 gap-4">
              {/* <Button
                variant="primary"
                type="button"
                className="w-40 fw-700 fs-6 popUpLogBtn2 mt-2"
                onClick={() => setView(0)}
              >
                Later
              </Button> */}
              <Button
                variant="primary"
                type="button"
                className="w-100 fw-700 fs-6 popUpLogBtn mt-2"
                onClick={joinSurvivour}
              >
                Join
              </Button>
            </div>
          </div>
        </>
      );
    }
    // else if (view === 3) {
    //   return (
    //     <>
    //       <div className="d-flex gap-4 justify-center lightGreen">
    //         <div>
    //           <Button
    //             className="!bg-[#08183b] !border-[#295392] competetionInfoBtns"
    //             onClick={() => setSelectedOption("createTeam")}
    //           >
    //             Create Team
    //           </Button>
    //         </div>
    //         <Button
    //           className="!bg-[#08183b] !border-[#295392] competetionInfoBtns"
    //           onClick={() => setSelectedOption("enterTeamWithRoomID")}
    //         >
    //           Enter Team With Room ID
    //         </Button>
    //         <Button
    //           className="!bg-[#08183b] !border-[#295392] competetionInfoBtns"
    //           onClick={() => setSelectedOption("randomTeam")}
    //         >
    //           Enter Random Team
    //         </Button>
    //       </div>
    //       <h3 className="text-white font-semibold text-center mt-5">
    //         Select Option For Start
    //       </h3>

    //       {selectedOption === "createTeam" && (
    //         <div className="mt-3">
    //           <Form.Group controlid="teamName">
    //             <Form.Label className="popupLabels pb-1 fw-400 text-white">
    //               Team Name
    //             </Form.Label>
    //             <div className="w-1/2">
    //               <Form.Control type="text" placeholder="Enter team name" />
    //             </div>
    //           </Form.Group>
    //           <Form.Check
    //             type="checkbox"
    //             label="Allow Random Team"
    //             className="mt-2 text-white"
    //           />
    //           <Form.Check
    //             type="checkbox"
    //             label="Have Waiting Room"
    //             className="mt-2 text-white"
    //           />
    //           <div className="w-100 pt-3 mt-2 d-flex flex-col items-center">
    //             <Button
    //               variant="primary"
    //               type="submit"
    //               className="w-60 fw-700 fs-6 popUpLogBtn"
    //             >
    //               Join
    //             </Button>
    //           </div>
    //         </div>
    //       )}
    //       {selectedOption === "enterTeamWithRoomID" && (
    //         <div className="mt-3">
    //           <Form.Group controlid="roomID">
    //             <Form.Label className="popupLabels pb-1 fw-400 text-white">
    //               Room ID
    //             </Form.Label>
    //             <div className="w-1/2">
    //               <Form.Control type="text" placeholder="Enter room ID" />
    //             </div>
    //           </Form.Group>
    //           <div className="w-100 pt-3 mt-2 d-flex flex-col items-center">
    //             <Button
    //               variant="primary"
    //               type="submit"
    //               className="w-60 fw-700 fs-6 popUpLogBtn"
    //             >
    //               Join
    //             </Button>
    //           </div>
    //         </div>
    //       )}
    //       {selectedOption === "randomTeam" && (
    //         <div className="w-100 pt-3 mt-2 d-flex flex-col items-center">
    //           <Button
    //             variant="primary"
    //             type="submit"
    //             className="w-60 fw-700 fs-6 popUpLogBtn"
    //           >
    //             Join
    //           </Button>
    //         </div>
    //       )}
    //     </>
    //   )
    // }
    else {
      return null;
    }
  };

  return (
    <Modal show={view !== 0} centered className="loginContainer competeInfoCon">
      <Modal.Header>
        {view == 1 ? (
          <h3 className="fs-4 text-white fw-600 competeInfoTitle">
            Team{" "}
            <span className="text-[#f1aa34]">
              ${data?.competitionsInfo?.team?.totalAmount}
            </span>{" "}
          </h3>
        ) : view == 2 ? (
          <h3 className="fs-3 text-white fw-600 competeInfoTitle">
            Survivor{" "}
            <span className="text-[#f1aa34]">
              ${data?.competitionsInfo?.survivor?.totalAmount}
            </span>
          </h3>
        ) : view == 3 ? (
          <h3 className="fs-4 text-white fw-600 competeInfoTitle">
            Both{" "}
            <span className="text-[#f1aa34]">
              ${data?.competitionsInfo?.both?.totalAmount}
            </span>
          </h3>
        ) : (
          ""
        )}

        <div className="d-flex justify-between gap-2 items-center">
          <Button className="!bg-[#08183b] !border-[#295392] !rounded-full competetionInfoBtns">
            $ {walletAmount}
          </Button>
          {/* <p className="fs-6 text-white fw-600 walletAmountValue">
            Wallet Amount
          </p> */}
          <IoMdClose
            color="#ffffff"
            fontSize={30}
            onClick={() => setView(0)}
            className="cursor-pointer"
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        {showPayment ? (
          <PaymentPopup
            setShowPayment={setShowPayment}
            showPayment={showPayment}
            paymenttext={paymenttext}
          />
        ) : (
          renderContent()
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CompeteInfoTeam;
