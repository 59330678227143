import React, {useEffect, useState} from "react"
import {Modal, Button, Form} from "react-bootstrap"
import OTPInput from "react-otp-input"
import {useNavigate} from "react-router-dom"
import {IoMdClose} from "react-icons/io"
import {IoArrowBackOutline} from "react-icons/io5"
import {FaEye} from "react-icons/fa"
import {AiFillAndroid} from "react-icons/ai"
import {toast} from "react-toastify"
import {
  useAddDetailsMutation,
  useResendOtpMutation,
  useVerifyOTPMutation,
} from "../../../../Store/api/ApiSlice"

const VerifyOtp = ({
  setView,
  refetch,
  userDetails,
  setAuthorisedUser,
  addUserDetailsFormData,
  setUserDetails
}) => {
  const navigate = useNavigate();
  const [OTPVerification, {isSuccess}] = useVerifyOTPMutation();
  const [AddUserDetails] = useAddDetailsMutation();

  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearTimeout(timer)
    } else {
      setIsResendDisabled(false)
    }
  }, [timeLeft])


  // Handle OTP Resend
  const handleResendOtp = async () => {
      const payload = {
        userId : userDetails?.userId,
        trackingCode : userDetails?.trackingCode,
        profilePicture : "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_640.png",
        fullName :  addUserDetailsFormData?.fullName,
        phoneNumber : addUserDetailsFormData?.phoneNumber,
        countryCode : "+1",
        dateOfBirth : addUserDetailsFormData?.dateOfBirth,
        referralCode : addUserDetailsFormData?.referralCode
      }
      setIsResendDisabled(true);
      AddUserDetails(payload)
      .unwrap()
      .then((res) => {
        if(res?.data?.user) {
          setUserDetails({
            ...userDetails,
            identifier: res?.data?.user?.identifierCode
          })
          setTimeLeft(60);
          toast.success("OTP resend successfully!");
        }
      })
      .catch((e) => {
        setIsResendDisabled(false);
        toast.error(e?.data?.message ?? "Something went wrong!")
      })
  };

  const handleChange = (value) => {
    setOtp(value)
  }

  const verifyyOtpFunc = (e) => {
    e.preventDefault()
    const body = {
      identifierCode: userDetails?.identifier,
      code: otp,
      purpose: "auth"
    }
    OTPVerification(body)
      .unwrap()
      .then((res) => {
        if (res?.data?.accessToken) {
          localStorage.setItem("loginData", JSON.stringify(res?.data))
          setAuthorisedUser(true)
          toast.success("User created succesfully")
          navigate("/eventPage")
          setView(0)
          refetch()
        } else {
          toast.error("Invalid OTP" + res?.data?.message)
        }
      })
      .catch((e) => {
        toast.error(e?.data?.message ?? "Something went wrong!")
      })
  }
  return (
    <>
      <Modal.Header className="border-none d-flex justify-center">
        <div className="flex gap-2 items-center">
          <IoArrowBackOutline
            color="#ffffff"
            fontSize={30}
            onClick={() => setView(3)}
            className="cursor-pointer"
          />
          <h3 className="fs-4 text-white fw-600">Verify OTP</h3>
        </div>
        <IoMdClose
          color="#ffffff"
          fontSize={30}
          onClick={() => setView(0)}
          className="cursor-pointer"
        />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={verifyyOtpFunc}>
          {/* <div className="d-flex justify-center items-center pt-2">
            <AiFillAndroid color="#fad550" size={70} />
          </div> */}
          <p className="text-white text-center">An OTP has been sent to your mobile number</p>
          <div className="d-flex justify-center items-center py-3">
            <OTPInput
              inputStyle={{
                width: "4vw",
                maxWidth: "48px",
                aspectRatio: "1/1",
                fontSize: "18px",
                borderRadius: "5px",
                border: "1px solid #CCCFD3",
                outline: "none",
                backgroundColor: "white",
                textAlign: "center",
                justifyContent: "center",
                transition: "background-color 0.3s",
                color: "black",
                marginLeft: "10px",
              }}
              numInputs={4}
              value={otp}
              onChange={handleChange}
              renderInput={(props) => (
                <input
                  {...props}
                  type="text"
                  inputMode="numeric"
                  maxLength={1}
                  pattern="\d*"
                />
              )}
            />
          </div>
          {/* Resend OTP Section */}
          <div className="text-center">
            <p className="text-white fs-6 fw-600">
              {isResendDisabled ? (
                <span className="text-secondary fs-6 fw-600">
                   Resend Code:{" "} 00:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}
                </span>
              ) : (
                <span
                  className="text-secondary fs-6 fw-600 cursor-pointer"
                  onClick={handleResendOtp}
                >
                  Resend Now
                </span>
              )}
            </p>
          </div>
          <div className=" w-100 pt-3 d-flex flex-col items-center">
            <Button
              variant="primary"
              type="submit"
              className="w-60 fw-700 fs-6 popUpLogBtn"
            >
              submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </>
  )
}

export default VerifyOtp
