import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const PaymentFailed = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => navigate("/homePage"), 5000); // Redirect after 5s
  }, [navigate]);

  return (
    <Container className="text-center mt-5">
      <h3>Payment Failed ❌</h3>
      <p>Redirecting you back to the home page...</p>
    </Container>
  );
};

export default PaymentFailed;
