import React, {useEffect} from "react"

import Navigation from "./components/navigation/Navigation"
import Home from "./components/home/Home"
import Moon from "./components/moon/Moon"
import Mission from "./components/mission/Mission"
import Work from "./components/work/Work"
import Expend from "./components/expend/Expend"
import TakeControl from "./components/takecontrol/TakeControl"
import Cator from "./components/cator/Cator"
import Trusted from "./components/trusted/Trusted"
import TrustedCards from "./components/TrustedCards/TrustedCards"
import FindWork from "./components/findwork/FindWork"
// import Footer from "./components/footer/Footer";

// for terms and condition and privacy policy pages
import {Routes, Route, useLocation} from "react-router-dom"
import PolicyPage from "./pages/policy"
import TermsPage from "./pages/New_T&C"

import AOS from "aos"
import "bootstrap/dist/css/bootstrap.min.css"
import "aos/dist/aos.css"
import "./App.css"
import EventsPage from "./pages/eventsPage/EventsPage"

import "../src/styleSheet/colors.css"
import Header from "./components/atoms/Header"
import HomePage from "./pages/homePage/HomePage"
import Footer from "./components/atoms/Footer"
import EventDetailsPage from "./pages/eventDetailsPage/EventDetailsPage"
import ConfirmationPage from "./pages/confirmationPage/ConfirmationPage"
import {AuthProvider} from "./Contex/AuthContext"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import PaypalProcessingPage from "./pages/Paypal/PaypalProcessingPage"
import PaymentSuccess from "./pages/Paypal/PaymentSuccess"
import PaymentFailed from "./pages/Paypal/PaymentFailed"


function App() {
  const location = useLocation()
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div className="flex flex-col min-h-screen">

        {/* Toast Notifications */}
        <ToastContainer position="top-right" draggable theme="light" />

        <AuthProvider>
        {/* Header */}
        <div className="fixed w-full z-10">
          <Header />
        </div>

        {/* Main Content - Pushes Footer to Bottom */}
        <div className="flex-1 mt-16">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/paypal-processing" element={<PaypalProcessingPage />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-failed" element={<PaymentFailed />} />
            <Route path="/homePage" element={<HomePage />} />
            <Route path="/policy" element={<PolicyPage />} />
            <Route path="/t&c" element={<TermsPage />} />
            <Route path="/eventPage" element={<EventsPage />} />
            <Route path="/eventDetailsPage" element={<EventDetailsPage />} />
            <Route path="/confirmationPage" element={<ConfirmationPage />} />
          </Routes>
        </div>

        {/* Footer - Always at Bottom */}
        <Footer className="w-full bg-gray-100 py-4 text-center" />
      </AuthProvider>
    </div>
  )
}

export default App
