import {useRef, useState} from "react"
import {Modal, Button, Form} from "react-bootstrap"
import {useNavigate} from "react-router-dom"
import {IoMdClose} from "react-icons/io"
import {IoArrowBackOutline} from "react-icons/io5"
import {
  useAddDetailsMutation,
  useCheckUserMutation,
  useImageUploadMutation,
} from "../../../../Store/api/ApiSlice"
import PhoneInputBox from "../../../atoms/PhoneInputBox"
import EditProfile from "../../../../assets/images/profileEdit.png"
import {toast} from "react-toastify"
import "./style.css"

const UserDetails = ({
  setView,
  userDetails,
  setUserDetails,
  addUserDetailsFormData,
  setAddUserDetailsFormData,
}) => {
  const fileInputRef = useRef(null);
  const [AddUserDetails, {isSuccess}] = useAddDetailsMutation();
  const [uploadFile, {isSuccess: uploadSuccess}] = useImageUploadMutation();
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!/^[A-Za-z]+\s[A-Za-z]+$/.test(addUserDetailsFormData?.fullName)) {
      newErrors.fullName = "Full name must contain only alphabets and should have first and last name.";
    }
    if (!/^\d{10}$/.test(addUserDetailsFormData?.phoneNumber)) {
      newErrors.phoneNumber = "Phone number must be exactly 10 digits.";
    }
    // Date of Birth Validation (Age Check: Must be more than 17 years old)
    const birthDate = new Date(addUserDetailsFormData?.dateOfBirth);
    const today = new Date();
    
    // Calculate the date exactly 17 years ago
    const minAllowedDate = new Date();
    minAllowedDate.setFullYear(today.getFullYear() - 17);

    if (!addUserDetailsFormData?.dateOfBirth) {
      newErrors.dateOfBirth = "Date of birth is required.";
    } else if (birthDate > today) {
      newErrors.dateOfBirth = "Date of birth cannot be in the future.";
    } else if (birthDate > minAllowedDate) {
      newErrors.dateOfBirth = "You must be older than 17 years.";
    }   
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDivClick = () => {
    fileInputRef.current.click()
  }
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    let formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "evpu4jkj"); // Cloudinary upload preset
    formData.append("cloud_name", "dn4fek7x1"); // Cloudinary cloud name
    formData.append("api_key", "693542494398254");
    formData.append("public_id", file?.name ? file?.name : 'default_image'); // Set file name or default
  
    try {
      const response = await fetch("https://api.cloudinary.com/v1_1/dn4fek7x1/image/upload", {
        method: "POST",
        body: formData,
      });
  
      const data = await response.json();
  
      if (data?.secure_url) {
        setAddUserDetailsFormData({
          ...addUserDetailsFormData,
          profilePicture: data?.secure_url,
        });
        toast.success("Image uploaded successfully!");
      } else {
        toast.error("Failed to upload image.");
      }
    } catch (error) {
      console.error("Image Upload Error:", error);
      toast.error("Something went wrong while uploading the image!");
    }
  };
  

  const handleUserDetailsSubmit = (e) => {
    e.preventDefault()
    if (!validateForm()) return;
    const payload = {
      userId : userDetails?.userId,
      trackingCode : userDetails?.trackingCode,
      profilePicture : addUserDetailsFormData?.profilePicture,
      fullName :  addUserDetailsFormData?.fullName,
      phoneNumber : addUserDetailsFormData?.phoneNumber,
      countryCode : "+1",
      dateOfBirth : addUserDetailsFormData?.dateOfBirth,
      referralCode : addUserDetailsFormData?.referralCode
    }
    AddUserDetails(payload)
        .unwrap()
        .then((res) => {
          if(res?.data?.user) {
            setUserDetails({
              ...userDetails,
              identifier: res?.data?.user?.identifierCode
            })
            toast.success("OTP sent successfully!")
            setView(4)
          }
        })
        .catch((e) => {
          toast.error(e?.data?.message ?? "Something went wrong!")
        })
  }

  return (
    <>
      <Modal.Header>
        <div className="flex gap-2 items-center">
          <IoArrowBackOutline
            color="#ffffff"
            fontSize={30}
            onClick={() => setView(2)}
            className="cursor-pointer"
          />
          <h3 className="fs-4 text-white fw-600">Personal Information</h3>
        </div>
        <IoMdClose
          color="#ffffff"
          fontSize={30}
          onClick={() => setView(0)}
          className="cursor-pointer"
        />
      </Modal.Header>
      <Modal.Body>
        <div className="text-white mb-2 d-flex flex-col">
          <h5 className="text-white fw-600">Enter Personal Information</h5>
          <p className="fs-6">
            Information Needed for when it's time to send you payments.
          </p>
          <>
            <div className="text-center py-3 d-flex justify-center">
              <img
                src={addUserDetailsFormData?.profilePicture ? addUserDetailsFormData?.profilePicture : EditProfile}
                alt="profile icon"
                width={80}
                height={80}
                onClick={() => fileInputRef.current.click()}
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              />
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{display: "none"}}
              onChange={handleFileChange}
              accept="image/*"
              className="cursor-pointer"
            />
          </>
        </div>
        <Form onSubmit={handleUserDetailsSubmit}>
          {/* Full Name Field */}
          <Form.Group className="mb-3" controlid="formFullName">
            <Form.Control
              type="text"
              placeholder="Full Name"
              value={addUserDetailsFormData?.fullName}
              onChange={(e) => setAddUserDetailsFormData({...addUserDetailsFormData, fullName: e.target.value})}
              required
            />
            {errors.fullName && <div className="text-danger">{errors.fullName}</div>}
          </Form.Group>

          {/* Phone Number Field */}
          {/* <div className="mb-3">
            <PhoneInputBox
              value={addUserDetailsFormData?.phoneNumber}
              onChange={(number)=> setAddUserDetailsFormData({...addUserDetailsFormData, phoneNumber: number})}
            />
          </div> */}
          <Form.Group className="mb-3" controlId="formPhoneNumber">
            <div className="d-flex align-items-center border rounded bg-[#ffe5e591]">
              <img
                src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                alt="USA flag"
                width={25}
                height={15}
                className="ml-2"
              />
              <Form.Control
                type="tel"
                placeholder="Phone Number"
                value={addUserDetailsFormData?.phoneNumber}
                onChange={(e) =>
                  setAddUserDetailsFormData({
                    ...addUserDetailsFormData,
                    phoneNumber: e.target.value,
                  })
                }
                // className="bg-transparent border-none"
                className="bg-transparent border-0 shadow-none focus:outline-none focus:ring-0"
                required
              />
            </div>
            {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
          </Form.Group>

          {/* Date of Birth Field */}
          <Form.Group className="mb-3" controlid="formDateOfBirth">
            <Form.Control
              type="date"
              placeholder="Date of Birth"
              value={addUserDetailsFormData?.dateOfBirth}
              onChange={(e) => setAddUserDetailsFormData({...addUserDetailsFormData, dateOfBirth: e.target.value})}
              required
            />
            {errors.dateOfBirth && <div className="text-danger">{errors.dateOfBirth}</div>}
          </Form.Group>

          {/* Referral Code Field */}
          <Form.Group className="mb-3" controlid="formReferralCode">
            <Form.Control
              type="text"
              placeholder="Referral Code (optional)"
              value={addUserDetailsFormData?.referralCode}
              onChange={(e) => setAddUserDetailsFormData({...addUserDetailsFormData, referralCode: e.target.value})}
            />
          </Form.Group>

          <div className="w-100 pt-3 d-flex flex-col items-center">
            <Button
              variant="primary"
              type="submit"
              className="w-60 fw-700 fs-6 popUpLogBtn"
            >
              Next
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </>
  )
}

export default UserDetails
