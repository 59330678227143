import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { ReactTyped } from "react-typed";

import EventCreationCard from "./EventCreationCard";
import MainEventCard from "./MainEventCard";
import { useGetEventListQuery } from "../../../Store/api/ApiSlice";

import SearchIcon from "../../../assets/icons/searchIcon.svg";
import "./upcomingEventSearch.css";

const UpcomingEventSearch = () => {
  const navigate = useNavigate();
  const { data: eventData, isSuccess } = useGetEventListQuery();

  const { latestEvent, groupEvent, latestEventDate , formattedStartDate } = useMemo(() => {
    const startDate = moment(eventData?.data?.event?.startDate).format("ddd M/D/YY");

    let startAndEndDateRange = `${moment(eventData?.data?.event?.startDate).format(
      "ddd DD/MM/yyyy "
    )} to ${moment(eventData?.data?.event?.endDate).format(
      "ddd DD/MM/yyyy "
    )}`;

    return {
      latestEvent: eventData?.data?.event,
      groupEvent: eventData?.data?.otherEvents,
      latestEventDate: startAndEndDateRange,
      formattedStartDate: startDate,
    };
  }, [eventData, isSuccess]);

  const eventDetailsFunc = () => {
    navigate("/eventDetailspage", { state: { eventData: latestEvent } });
  };

  console.log("EventData", {
    latestEvent,
    groupEvent,
    latestEventDate,
    formattedStartDate,
  });

  return (
    <>
      {isSuccess && (
        <Container>
          <Row className="justify-content-center text-center mt-6">
            <Col md={12}>
              {/* <h2 className="display-4 font-weight-bold text-center AELiveTitle textPrimary ">
                Compete AE Live.
              </h2> */}
               {/* <p className="h4 earnText">
                */}
                <h2 className="display-4 font-weight-bold text-center AELiveTitle textPrimary ">
                  Compete {""}
                  <ReactTyped
                    // strings={["And Earn, Compete At Everything, Compete And Excel"]}
                    strings={[
                      "And Earn",
                      "At Everything",
                      "And Excel",
                      "And Experience"
                    ]}
                    typeSpeed={70}
                    backSpeed={70}
                    // loop
                    loop={false}
                    backDelay={1000}
                    showCursor={false}
                  />
                  </h2>
                  {/* #F1AA34", "#B624DA" */}
                  <p className="text-center d-lg-block d-none opacity-80 d-lg-block h4 fw-600 text-white enjoyNewYork"><span className="fw-600">Compete </span>, Win <span className="text-[#ffee56] fw-600"> Cash</span> and Earn a <span className="fw-600"> Trophies</span>  in every competition. <span className="text-[#ffee56] fw-600">Join Now </span> </p>
                  {/* <p className="text-center h4 fw-600 text-white enjoyNewYork">Compete, Win <span className="text-[#F1AA34] fw-600"> Cash</span> and Earn a <span className="text-[#B624DA] fw-600"> Trophies</span>  in every competition. Join Now </p> */}
                {/* </p> */}
              <div className="d-flex justify-content-center mt-3 mx-auto">
                <div className="position-relative eventSearchInput">
                  <input
                    type="text"
                    placeholder="Search for an Upcoming Event"
                    className="py-3 px-4 rounded-pill eventSearch"
                  />
                  <img
                    src={SearchIcon}
                    alt="Search"
                    width={22}
                    height={22}
                    className="position-absolute"
                    style={{ top: "22px", left: "31px" }}
                  />
                </div>
              </div>
              <div className="mt-3">
                {/* <h2 className="display-4 font-weight-bold text-center AELiveTitle textPrimary ">
                  Compete And Earn At Everything And Excel And Experience
                </h2> */}
                {/* <p className="h4 earnText">
                  Compete {""}
                  <ReactTyped
                    // strings={["And Earn, Compete At Everything, Compete And Excel"]}
                    strings={[
                      "And Earn",
                      "At Everything",
                      "And Excel",
                      "And Experience",
                    ]}
                    typeSpeed={70}
                    backSpeed={70}
                    loop
                  />
                </p> */}
              </div>
            </Col>
          </Row>

          {/* Next Competition Experience Section */}
          <div>
            <div className="nextBackground-layer"></div>
            <Row className="text-white gap-1 nextEventSec">
              <p className="text-start text-20 nextCompetText fw-500 px-0 nextCompetTitle">
                Next Competition Experience
              </p>
              <MainEventCard
                latestEvent={latestEvent}
                latestEventDate={latestEventDate}
                eventDetailsFunc={eventDetailsFunc}
                formattedStartDate={formattedStartDate}
              />
            </Row>
          </div>

          {/* Future Competitions Experience Component */}
          <EventCreationCard data={groupEvent} formattedStartDate={formattedStartDate}/>

          {/*Enjoy New York's Title  */}
          {/* <div className=" d-flex flex-col justify-content-center align-items-center"> */}
            {/* <p className="text-center h4 fw-600 text-white enjoyNewYork">
              Enjoy New York's most exciting events and experiences,
              <br />
              get access to top tourist attractions, discover tons of secret
              places
              <br />
              and find out about the best events and activities.
            </p> */}
             <p className="text-center d-lg-none d-block d-lg-block h4 fw-600 text-white enjoyNewYork mb-0"><span className="text-[#B624DA] fw-600">Compete </span>, Win <span className="text-[#F1AA34] fw-600"> Cash</span> and Earn <span className="text-[#B624DA] fw-600"> Trophies</span>  in every competition. <span className="text-[#F1AA34] fw-600">Join Now </span> </p>
          {/* </div> */}
        </Container>
      )}
    </>
  );
};

export default UpcomingEventSearch;
