import React, {useEffect, useState} from "react"
import {Container, Navbar, Nav, Button} from "react-bootstrap"
import {Link, useNavigate, useSearchParams} from "react-router-dom"
import {CgMenuLeft} from "react-icons/cg"
import {AiOutlineClose} from "react-icons/ai"

import AuthPopUp from "../molecules/popUps/auth/AuthPopUp"

import logo from "../../assets/images/lgo.svg"

import ProfileIcon from "../../assets/icons/profileIcon.svg"

import "./style.css"
import PaymentPopup from "../molecules/popUps/PaymentPopup"
import {useGetWalletQuery} from "../../Store/api/ApiSlice" // Import the context
import {useAuth} from "../../Contex/AuthContext"
import NotifyMe from "../molecules/popUps/auth/NotifyMe"

const Header = () => {
  const navigate = useNavigate()
  const [scrolled, setScrolled] = useState(false)
  const [toggleMenu, setToggleMenu] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const [params] = useSearchParams()
  const [authorisedUser, setAuthorisedUser] = useState(false)
  const {data: walletData, isSuccess, refetch} = useGetWalletQuery()
  const [walletAmount, setWalletAmount] = useState(null)
  const [notifyMe, setNotifyMe] = useState(false)
  const {view, openAuthPopup, closeAuthPopup, isAuthPopupOpen, setView} =
    useAuth() // Use context

  const getAmount = () => {
    let finalAmount =
      walletData?.data?.amount + walletData?.data?.referralAmount
    finalAmount = (finalAmount - walletData?.data?.withdrawalAmount).toFixed(2)
    setWalletAmount(finalAmount)
  }

  const handleNotifyMe = () => {
    setNotifyMe(true)
  }
  const handleLogout = () => {
    localStorage.clear()
    window.location.reload()
  }

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0
      setScrolled(isScrolled)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    let data = localStorage.getItem("loginData")
    data = JSON.parse(data)
    if (data?.data?.accessToken) {
      setAuthorisedUser(true)
    } else {
      setAuthorisedUser(false)
    }
  }, [])

  useEffect(() => {
    if (authorisedUser) {
      getAmount()
    }
  }, [isSuccess])

  // useEffect(() => {
  //   if (params.get("token")) {
  //     setShowPayment(true)
  //   }
  // }, [params.get("token")])


  return (
    <header
      className={`headerContainer text-white py-lg-2 py-0 px-lg-5 px-0 ${
        scrolled ? "scrolled" : ""
      }`}
      style={{zIndex: "0"}}
    >
      <Navbar expand="lg" className="p-0 w-100">
        {/* Mobile view */}
        {/* <CgMenuLeft
          className="mt-1 me-lg-3 me-0 d-lg-none d-block burgurMenu"
          color="#fff"
          cursor="pointer"
          fontSize={30}
          onClick={() => setToggleMenu(true)}
        />

        <Navbar.Brand href="#" className="d-flex align-items-center headerLogo">
          <img src={logo1} alt="Compete AE" className="cae-logo"/>
        </Navbar.Brand>

        <a
                  href="https://apps.apple.com/us/app/competeae/id6444800738"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-auto text-decoration-none text-white"
                >
          <button className="joinInBtn bg_download mt-3">
          Download App
        </button>
        </a> */}
        <div className="d-flex justify-content-between align-items-center w-100">
          <CgMenuLeft
            className="mt-1 d-lg-none d-block burgurMenu"
            color="#fff"
            cursor="pointer"
            fontSize={30}
            onClick={() => setToggleMenu(true)}
          />

          <Navbar.Brand
            href="#"
            className="d-flex m-0 align-items-center headerLogo"
          >
            <img src={logo} alt="Compete AE" className="cae-logo" />
          </Navbar.Brand>

          <a
            href="https://apps.apple.com/us/app/competeae/id6444800738"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none text-white d-lg-none d-block"
          >
            <button
              className="joinInBtn bg_download me-2"
              style={{width: "95px", fontSize: "10px", marginTop: "3px"}}
            >
              Download App
            </button>
          </a>
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto d-flex align-items-center gap-4 text-white fw-600 headerNavs">
            <Nav.Link href="/homePage">Home</Nav.Link>
            {/* <Nav.Link href="/homePage">Product</Nav.Link>
            <Nav.Link href="/homePage">About</Nav.Link> */}
            <Nav.Link href="#" onClick={() => navigate("/eventPage")}>
              Event
            </Nav.Link>
          </Nav>

          {authorisedUser && (
            <Nav className="ml-4">
              <div>
                <Button 
                 onClick={() =>
                  authorisedUser ? setShowPayment(true) : openAuthPopup()
                }
                className=" walletField border-0 rounded-pill fw-600 px-3">
                  ${walletAmount}
                </Button>
              </div>
            </Nav>
          )}
          {authorisedUser && (
            <Nav className="ml-4">
              <div>
                <Button
                  onClick={() =>
                    authorisedUser ? setShowPayment(true) : openAuthPopup()
                  }
                  className="py-2 px-3 downloadAppBtn border-0 rounded-pill fw-600"
                >
                  Payment
                </Button>
              </div>
            </Nav>
          )}
          <Nav className="ml-4 pr-10">
            <div>
              <a
              // href="https://apps.apple.com/in/app/competeae/id6444800738"
              // target="blank"
              >
                <Button
                  className="py-2 px-3 downloadAppBtn border-0 rounded-pill fw-600"
                  onClick={handleNotifyMe}
                  style={{width: "136px"}}
                >
                  Notify Me
                </Button>
              </a>
            </div>
          </Nav>
          <Nav className="ml-4">
            <Button
              className="py-2 px-3 bg-transparent border-0 rounded-pill fw-600"
              onClick={authorisedUser ? handleLogout : () => setView(1)}
            >
              {authorisedUser ? "Logout" : "Login"}
            </Button>
            {/* <div variant="link">
              <img src={ProfileIcon} alt="Profile" width={40} height={40} />
            </div>
            <select
              className="languageSelect form-select bg-transparent text-white border-0 outline-0"
              aria-label=".form-select-lg example"
            >
              <option selected className="text-black">
                EN
              </option>
              <option value="1" className="text-black">
                HI
              </option>
              <option value="2" className="text-black">
                IT
              </option>
              <option value="3" className="text-black">
                Ch
              </option>
            </select> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {toggleMenu && (
        <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
          <AiOutlineClose
            fontSize={27}
            className="overlay__close"
            onClick={() => setToggleMenu(false)}
          />
          <ul className="app__navbar-smallscreen_links ps-0">
            <Link to={"/"}>
              <li>
                <a onClick={() => setToggleMenu(false)}>Home</a>
              </li>
            </Link>
            <Link to={"#"}>
              <li>
                <a
                  onClick={() => {
                    setToggleMenu(false);  // Close the mobile menu
                    if (authorisedUser) {
                      handleLogout();  // Call handleLogout if the user is logged in
                    } else {
                      openAuthPopup();  // Open the login popup if not logged in
                    }
                  }}
                >
                  {authorisedUser ? "Logout" : "Login"} {/* This will change to "Logout" if authorisedUser is true */}
                </a>
              </li>
            </Link>
            {authorisedUser && 
            <Link to={"#"}>
              <li>
                <a
                  onClick={() => {
                    setToggleMenu(false);  // Close the mobile menu
                    if (authorisedUser) {
                      setShowPayment(true)
                    }
                    else {
                      openAuthPopup();  // Open the login popup if not logged in
                  }
                  }}
                >
                  Payment {/* This will change to "Logout" if authorisedUser is true */}
                </a>
              </li>
            </Link>}
            {/* <Link to={"/homePage"}>
              <li>
                <a onClick={() => setToggleMenu(false)}>About</a>
              </li>
            </Link> */}
            <Link to={"/eventPage"}>
              <li>
                <a onClick={() => setToggleMenu(false)}>Event</a>
              </li>
            </Link>
          </ul>
        </div>
      )}

      <AuthPopUp
        setView={setView}
        view={view}
        setAuthorisedUser={setAuthorisedUser}
        refetch={refetch}
      />
      {notifyMe && <NotifyMe setNotifyMe={setNotifyMe} />}
      {showPayment && (
        <PaymentPopup
          setShowPayment={setShowPayment}
          showPayment={showPayment}
        />
      )}
    </header>
  )
}

export default Header
