import {useState} from "react"
import {Modal, Button, Form} from "react-bootstrap"
import {useNavigate} from "react-router-dom"
import {FaEye, FaEyeSlash} from "react-icons/fa"
import {IoMdClose} from "react-icons/io"
import {
  useSignInMutation,
  useSignupMutation,
  useSubscribeMutation
} from "../../../../Store/api/ApiSlice"
import logo from "../../../../assets/images/lgo.svg"
import "./style.css"
import {useAuth} from "../../../../Contex/AuthContext"
import {toast} from "react-toastify"
const NotifyMe = ({setNotifyMe}) => {
  const [SignIn, {isSuccess}] = useSignInMutation()
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const handleFullnameChange = (e) => setFullName(e.target.value)
  const handleEmailChange = (e) => setEmail(e.target.value)
  const [suscribe, {isSuccess: suscribeSuccess}] =
  useSubscribeMutation()

  const loginFunc = (e) => {
    e.preventDefault()
    let subcribeValue = { email: `event$$${fullName}$$${email}` };
    suscribe(subcribeValue)
  }

  return (
    <Modal
      show={true}
      onHide={() => setNotifyMe(false)}
      centered
      className="loginContainer"
    >
    <>
      <Modal.Header>
        <h3 className="fs-4 text-white fw-600">Notify Me</h3>
        <IoMdClose
          color="#ffffff"
          fontSize={30}
          onClick={() => setNotifyMe(false)}
          className="cursor-pointer"
        />
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-1 d-flex justify-center">
          <img
            src={logo}
            alt="Compete AE"
            className=""
            style={{width: "150px"}}
          />
        </div>
        <Form>
          <Form.Group className="mb-3" controlid="formUsername">
            <p className="popupLabels pb-1 fw-400 text-white">Fullname</p>
            <Form.Control
              type="text"
              placeholder="Enter Fullname"
              onChange={handleFullnameChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlid="formPassword">
            <p className="popupLabels pb-1 fw-400 text-white">Email</p>
            <Form.Control
              type="text"
              placeholder="Enter Email"
              onChange={handleEmailChange}
            />
          </Form.Group>
          <div className=" w-100 pt-3 d-flex flex-col items-center">
            <Button
              variant="primary"
              type="submit"
              className="w-60 fw-700 fs-6 popUpLogBtn"
              onClick={loginFunc}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </>
    </Modal>
  )
}

export default NotifyMe;
