import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spinner, Container } from "react-bootstrap";
import { useConfirmPaymentMutation } from "../../Store/api/ApiSlice";

const PaypalProcessingPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paypalToken = sessionStorage.getItem("paypalToken");
  const [confirmPaypalPayment, { isLoading, isError }] = useConfirmPaymentMutation();

  useEffect(() => {
    const token = searchParams.get("token");

    if (token) {
      confirmPaypalPayment({ paymentId : token, token : paypalToken })
        .then(() => {
          navigate("/payment-success"); // Redirect to success page
        })
        .catch(() => {
          navigate("/payment-failed"); // Redirect to failure page
        });
    } else {
      navigate("/payment-failed"); // Redirect if no payment details are found
    }
  }, [searchParams, confirmPaypalPayment, navigate]);

  return (
    <Container className="text-center mt-5">
      <h3>Processing your payment...</h3>
      <Spinner animation="border" />
      <p>Please wait while we confirm your payment.</p>
    </Container>
  );
};

export default PaypalProcessingPage;
