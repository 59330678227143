import moment from "moment"
import React, {useRef, useState} from "react"
import {useEffect} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {useAuth} from "../../../Contex/AuthContext"
import CompeteInfoTeam from "../popUps/competeInfoPopUp/CompeteInfoTeam"

const EventsPriceCard = ({data, setView}) => {
  const navigation = useNavigate()
  const location = useLocation().state;
  const [view, setcardView] = useState(0)
  const [selectedSection, setSelectedSection] = useState("")
  const [minAllowedEvent, setminAllowedEvent] = useState("")
  const eventPriceCardRef = useRef(null)

  const handleShowDatesClick = () => {
    if (eventPriceCardRef.current) {
      // Scroll the eventPriceCard into the center of the viewport
      eventPriceCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center", // This will align the div in the center of the viewport
      })
    }
  }

  const eventDateData = [
    {
      competSection: "Survivor",
      time: {
        start: `Starts: ${moment(
          data?.competitionsInfo?.survivor?.startDate
        ).format("M/D/YY h:mm A")}`,
        end: `End: ${moment(data?.competitionsInfo?.survivor?.endDate).format(
          "M/D/YY h:mm A"
        )}`,
      },
      costFrom: data?.competitionsInfo?.survivor?.totalAmount || "0",
      allowed: data?.competitionsInfo?.survivor?.allowed,
    },
    {
      competSection: "Team",
      time: {
        start: `Starts: ${moment(
          data?.competitionsInfo?.team?.startDate
        ).format("M/D/YY h:mm A")}`,
        end: `End: ${moment(data?.competitionsInfo?.team?.endDate).format(
          "M/D/YY h:mm A"
        )}`,
      },
      costFrom: data?.competitionsInfo?.team?.totalAmount || "0",
      allowed: data?.competitionsInfo?.team?.allowed,
    },
    {
      competSection: "Both",
      time: {
        start: `Starts: ${moment
          .min(
            moment(data?.competitionsInfo?.team?.startDate),
            moment(data?.competitionsInfo?.survivor?.startDate)
          )
          .format("M/D/YY h:mm A")}`,
        end: `End: ${moment
          .max(
            moment(data?.competitionsInfo?.team?.endDate),
            moment(data?.competitionsInfo?.survivor?.endDate)
          )
          .format("M/D/YY h:mm A")}`,
      },
      costFrom: data?.competitionsInfo?.both?.totalAmount || "0",
      allowed: data?.competitionsInfo?.both?.allowed,
    },
  ]

  useEffect(() => {
    if (data) {
      let a = eventDateData
        .filter((event) => event?.allowed === true)
        .reduce((minEvent, currentEvent) => {
          return parseFloat(currentEvent?.costFrom) <
            parseFloat(minEvent?.costFrom)
            ? currentEvent
            : minEvent
        })
      setminAllowedEvent(a)
    }
  }, [data])

  const handleCompeteInfoOpen = (section) => {
    let loginData = JSON.parse(localStorage.getItem("loginData"));
    if (loginData?.data?.accessToken == null) {
      setView(1)
    } else {
      setSelectedSection(section)
      if (section === "Team") {
        setcardView(1)
      } else if (section === "Survivor") {
        setcardView(2)
      } else if (section === "Both") {
        setcardView(3)
      } else {
        setcardView(1)
      }
    }
  }

  console.log("EventsPriceCard", data)
  console.log("CardView", view)

  return (
    <>
      <div className="fixed w-100 justify-between align-items-center sticky_price left-0 right-0 z-50 bg-event-price p-3 bottom-0 d-lg-none d-flex">
        <h6 className="fw-500 mb-0">
          From ${minAllowedEvent?.costFrom}/<span>person</span>
        </h6>
        <button
          className="join-btn me-2"
          style={{
            width: "43%",
            fontSize: "14px",
            backgroundColor: "rgb(241, 170, 52)",
          }}
          onClick={handleShowDatesClick}
        >
          Get My Tickets
        </button>
      </div>

      <div
        className="glassy bg-slate-500 eventPriceCard p-2"
        ref={eventPriceCardRef}
      >
        <div className="eventPriceFrom">
          <h1 className="fw-500">
            From $ {minAllowedEvent?.costFrom}/<span>person</span>
          </h1>
          {/* <p className="fw-400 cursor-pointer">show all prices</p> */}
        </div>

        {/* <div className="selecteEventDate d-flex justify-content-between mt-4">
        <div className="selecteDateCol">
          <h1 className="fs-5">Dates</h1>
          <p className="fs-6 pt-1">Add dates</p>
        </div>
        <div className="verticalLine"></div>
        <div className="selecteDateCol">
          <h1 className="fs-5">Dates</h1>
          <p className="fs-6 pt-1">Add dates</p>
        </div>
      </div> */}

        {eventDateData?.map((eventDate, index) => {
          const isThirdToLast = index === eventDateData.length - 1

          return eventDate?.allowed ? (
            <div key={index}>
              <div className="flex flex-col gap-2">
                <div className="d-flex justify-content-between">
                  <div className="eventTimeSec">
                    <h1
                      className="fw-700 cursor-pointer"
                      onClick={() =>
                        handleCompeteInfoOpen(eventDate.competSection)
                      }
                    >
                      {eventDate?.competSection}
                    </h1>
                    {/* <h6 className="fw-500 pt-1">{eventDate?.time}</h6>
                     */}
                    <p className="fw-500 pt-1">
                      <div>{eventDate?.time?.start}</div>
                      <div>{eventDate?.time?.end}</div>
                    </p>
                  </div>
                  <div className="eventTimeSec d-flex  flex-col">
                    <h1 className="fw-700 text-center">
                      ${eventDate?.costFrom}
                      <span className="fs-6 fw-400">/person</span>
                    </h1>
                    <div className="flex items-center  gap-2 w-full">
                      {data?.status == "active" ? (
                        data?.userParticipation?.team &&
                        eventDate.competSection == "Team" ? (
                          <button
                            onClick={() => {
                              navigation("/confirmationPage", {
                                state: {
                                  categoryId: data?.categoryId,
                                  categoryName: location.categoryName,
                                  data: data?.userParticipation,
                                },
                              })
                            }}
                            className="px-3 py-2 rounded bg-[#3ea2ff]"
                          >
                            View
                          </button>
                        ) : 
                          data?.userParticipation?.survivor &&
                          eventDate.competSection == "Survivor" ? (
                          <button
                            onClick={() => {
                              navigation("/confirmationPage", {
                                state: {
                                  categoryId: data?.categoryId,
                                  categoryName: location.categoryName,
                                  data: data?.userParticipation,
                                },
                              })
                            }}
                            // className="px-3 py-2 rounded bg-[#3ea2ff]"
                            className="px-3 py-1 competSection-btn  rounded bg-[#3ea2ff]"
                          >
                            View
                          </button>
                        ) : (
                          <button
                            onClick={
                              (data?.userParticipation?.survivor &&
                                eventDate.competSection == "Both") ||
                              (data?.userParticipation?.team &&
                                eventDate.competSection == "Both")
                                ? null
                                : () =>
                                    handleCompeteInfoOpen(
                                      eventDate.competSection
                                    )
                            }
                            className="px-3 py-1 competSection-btn  rounded bg-[#3ea2ff]"
                          >
                            Join
                          </button>
                        )
                      ) : (
                        <button
                          onClick={() => {
                            navigation("/confirmationPage", {
                              state: {
                                categoryId: data?.categoryId,
                                categoryName: location.categoryName,
                                data: data?.userParticipation,
                              },
                            })
                          }}
                          className="px-3 py-1 competSection-btn  rounded bg-[#3ea2ff]"
                        >
                          Sold out
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="flex items-center  gap-2 w-full">
                {data?.status == "active" ? (
                  data?.userParticipation &&
                  data?.userParticipation?.team &&
                  eventDate.competSection == "Team" ? (
                    <button
                      onClick={() => {
                        navigation("/confirmationPage", {
                          state: {
                            data: data?.userParticipation,
                          },
                        })
                      }}
                      className="px-3 py-2 rounded bg-[#3ea2ff]"
                    >
                      View
                    </button>
                  ) : data?.userParticipation &&
                    data?.userParticipation?.survivor &&
                    eventDate.competSection == "Survivor" ? (
                    <button
                      onClick={() => {
                        navigation("/confirmationPage", {
                          state: {
                            data: data?.userParticipation,
                          },
                        })
                      }}
                      className="px-3 py-2 rounded bg-[#3ea2ff]"
                    >
                      View
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        handleCompeteInfoOpen(eventDate.competSection)
                      }
                      className="px-3 py-2 rounded bg-[#3ea2ff]"
                    >
                      Join
                    </button>
                  )
                ) : (
                  <button
                    onClick={() => {
                      navigation("/confirmationPage", {
                        state: {
                          data: data?.userParticipation,
                        },
                      })
                    }}
                    className="px-3 py-2 rounded bg-[#3ea2ff]"
                  >
                    Sold out
                  </button>
                )}
              </div> */}
              </div>
              {/* <div className="bottomHrLine" />
               */}
              {!isThirdToLast && <div className="bottomHrLine" />}
            </div>
          ) : null
        })}
        {/* ) : (
             <></>
           )
         )} */}

        {view !== 0 && (
          <CompeteInfoTeam
            setView={setcardView}
            view={view}
            competSection={selectedSection}
            data={data}
          />
        )}
      </div>
    </>
  )
}

export default EventsPriceCard
