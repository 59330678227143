import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import EditionPhotoshoot from "./EditionPhotoshoot";
import ExperienceHosted from "./ExperienceHosted";
import WhatIncluded from "./WhatIncluded";
import MeetYourHost from "./MeetYourHost";
import ThingsToknow from "./ThingsToknow";
import SimilarExperiences from "../sliders/SimilarExperiences";
import { useLocation } from "react-router-dom";
import { useGetEventByIdQuery } from "../../../Store/api/ApiSlice";
import PhotoshootSlider from "../sliders/PhotoshootSlider";
import { useAuth } from "../../../Contex/AuthContext";

import bgMain from "../../../assets/event/Ellipse1.png";

const EventDetails = () => {
  const [mobileSlider, setMobileSlider] = useState(false);
  const location = useLocation();
  const { eventData } = location.state || {};
  const { data } = useGetEventByIdQuery({
    id: eventData?.eventId ? eventData?.eventId : eventData?._id,
  });
  const { setView } = useAuth();


  /* mobile screen */
  useEffect(() => {
    const handleResize = () => {
      setMobileSlider(window.innerWidth <= 575);
    };
    handleResize(); // Call initially
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log("EventDetails", data);

  return (
    <div className="text-white relative">
      <img className="absolute z-0" src={bgMain} alt="" />
      {mobileSlider ? (
        <PhotoshootSlider data={data}/>
      ) : (
        <EditionPhotoshoot data={data?.data} />
      )}

      <ExperienceHosted data={data?.data} setView={setView} />
      <div className="bottomHrLine w-100" />
      <WhatIncluded data={data?.data} />
      <div className="bottomHrLine w-100" />

      <ThingsToknow data={data?.data} />

      {/* <div className="bottomHrLine w-100" /> */}

      {/* Accessibility section */}
      {/* <div className="accessibilSec pb-0">
        <h1 className="fw-700 fs-33 textSecondary">Accessibility</h1>
        <p className="h5">
          Free admission for people assisting
          <br />
          guests with disabilities{" "}
        </p>
      </div> */}
        {/* <button className="featureDetailsBtn fw-700">
          Show all feature details
        </button> */}

      {/* <div className="bottomHrLine w-100" /> */}
      <MeetYourHost data={data?.data} />
      <div className="bottomHrLine w-100" />
      {/* <ThingsToknow data={data?.data} /> */}
      <SimilarExperiences />
    </div>
  );
};

export default EventDetails;
