import React, {useState, useEffect} from "react"
import {Modal, Button, Form, Spinner} from "react-bootstrap"
import {useNavigate, useSearchParams} from "react-router-dom"
import logo from "../../../assets/images/lgo.svg"
import {
  useCardPaymentMutation,
  useDeleteCardMutation,
  useGetSavedCardsQuery,
  usePaypalPaymentMutation,
  useConfirmPaymentMutation,
} from "../../../Store/api/ApiSlice"
import { IoMdClose, IoMdAddCircle, IoMdTrash } from "react-icons/io";
import { toast } from "react-toastify"

const PaymentPopup = ({setShowPayment, showPayment, paymenttext}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { data: savedCards, refetch } = useGetSavedCardsQuery();
  const [deleteSavedCard, { isLoading: isDeleting }] = useDeleteCardMutation();
  const [pay, { isLoading: isPaying }] = useCardPaymentMutation();
  const [paypalPay, { isLoading: isPaypalPaying }] = usePaypalPaymentMutation();
  const [confirmPaypalPayment] = useConfirmPaymentMutation();

  const [amount, setAmount] = useState(0);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showNewCardFields, setShowNewCardFields] = useState(false);
  const [newCard, setNewCard] = useState({
    cardHolderName: "",
    cardNumber: "",
    cardExpire: "",
    cardSecurityCode: ""
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!amount || amount <= 0) newErrors.amount = "Amount must be greater than 0";
    if (showNewCardFields) {
      if (!newCard.cardHolderName) newErrors.cardHolderName = "Cardholder name is required";
      if (!newCard.cardNumber) newErrors.cardNumber = "Card number is required";
      if (!newCard.cardExpire) newErrors.cardExpire = "Expiration date is required";
      if (!newCard.cardSecurityCode) newErrors.cardSecurityCode = "Security code is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDeleteCard = async (cardId) => {
    deleteSavedCard(cardId)
    .unwrap()
    .then(() => {
      refetch();
    })
    .catch((error) => {
      toast.error("Error deleting card");
    });
  };

  const handlePayment = async () => {
    if (!validateForm()) return;
    try {
      let payload;
      if (selectedCard) {
        payload = {
          amount: amount * 100, // Convert to cents
          paymentThrough: "existingCard",
          cardId: selectedCard
        };
      } else {
        payload = {
          amount: amount * 100, // Convert to cents
          paymentThrough: "newCard",
          ...newCard
        };
      }

      await pay(payload).unwrap();
      toast.success("Payment successful!");
      setShowPayment(false);
    } catch (error) {
      console.error("Payment failed:", error);
      toast.error("Payment failed. Please try again.");
    }
  };

  const handlePaypalPayment = async () => {
    if (!amount || amount <= 5) {
      toast.error("Amount must be greater than $5");
      return;
    }
    try {
      const response = await paypalPay({ amount: amount * 100 }).unwrap();
      const paypalLink = response?.data?.result?.links.find(link => link.rel === "payer-action").href;
      const token = response?.data?.token;
      sessionStorage.setItem("paypalToken", token);
      window.location.href = paypalLink;
    } catch (error) {
      console.error("PayPal payment initiation failed:", error);
    }
  };

  // useEffect(() => {
  //   const paymentId = searchParams.get("paymentId");
  //   const token = searchParams.get("token");
  //   if (paymentId && token) {
  //     confirmPaypalPayment({ paymentId, token })
  //       .then(() => {
  //         toast.success("Payment successful!");
  //         setShowPayment(false);
  //         navigate("/home");
  //       })
  //       .catch((error) => console.error("Error confirming PayPal payment:", error));
  //   }
  // }, [searchParams, confirmPaypalPayment, navigate, setShowPayment]);

  return (
    <Modal show={showPayment} 
      centered 
      className="loginContainer"
    >
      <Modal.Header>
        <h3 className="fs-4 text-white fw-600">{paymenttext || "Payment"}</h3>
        <IoMdClose onClick={() => setShowPayment(false)} className="cursor-pointer text-white" />
      </Modal.Header>
      <Modal.Body className="text-white">
        <Form>
          <Form.Group className="mb-3">
            <Form.Label className="pb-1 fw-400 text-white">Enter Amount</Form.Label>
            <Form.Control
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              isInvalid={!!errors.amount}
            />
            <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
          </Form.Group>

          <h5>Saved Cards</h5>
          {savedCards?.data?.length > 0 ? (
            savedCards?.data?.map((card) => (
              <div key={card._id} className="d-flex justify-content-between align-items-center">
                <Form.Check
                  type="radio"
                  label={`${card.brand} ending in ${card.lastDigits}`}
                  name="savedCard"
                  onChange={() => {
                    setSelectedCard(card._id);
                    setShowNewCardFields(false);
                  }}
                  checked={selectedCard === card._id}
                />
                <Button variant="danger" size="sm" onClick={() => handleDeleteCard(card._id)} disabled={isDeleting}>
                  {isDeleting ? <Spinner animation="border" size="sm" /> : <IoMdTrash />}
                </Button>
              </div>
            ))
          ) : (
            <p>No saved cards found.</p>
          )}

          {!showNewCardFields && (
            <p
              className="text-primary text-decoration-underline cursor-pointer mt-2 mb-6"
              onClick={() => {
                setShowNewCardFields(true);
                setSelectedCard(null);
              }}
            >
              Add New Card
            </p>
          )}

          {showNewCardFields && (
            <>
              <h5>New Card Details</h5>
              <Form.Group className="mb-3">
                <Form.Label>Cardholder Name</Form.Label>
                <Form.Control
                  type="text"
                  value={newCard.cardHolderName}
                  onChange={(e) => setNewCard({ ...newCard, cardHolderName: e.target.value })}
                  isInvalid={!!errors.cardHolderName}
                />
                <Form.Control.Feedback type="invalid">{errors.cardHolderName}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Card Number</Form.Label>
                <Form.Control
                  type="text"
                  value={newCard.cardNumber}
                  onChange={(e) => setNewCard({ ...newCard, cardNumber: e.target.value })}
                  isInvalid={!!errors.cardNumber}
                />
                <Form.Control.Feedback type="invalid">{errors.cardNumber}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Expiration Date</Form.Label>
                <Form.Control
                  type="month"
                  value={newCard.cardExpire}
                  onChange={(e) => setNewCard({ ...newCard, cardExpire: e.target.value })}
                  isInvalid={!!errors.cardExpire}
                />
                <Form.Control.Feedback type="invalid">{errors.cardExpire}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Security Code</Form.Label>
                <Form.Control
                  type="text"
                  value={newCard.cardSecurityCode}
                  onChange={(e) => setNewCard({ ...newCard, cardSecurityCode: e.target.value })}
                  isInvalid={!!errors.cardSecurityCode}
                />
                <Form.Control.Feedback type="invalid">{errors.cardSecurityCode}</Form.Control.Feedback>
              </Form.Group>
            </>
          )}

          <Button variant="primary" className="w-100" onClick={handlePayment} disabled={isPaying}>
            {isPaying ? <Spinner animation="border" size="sm" /> : "Pay Now"}
          </Button>

          <div className="text-center my-3">----- OR -----</div>
          
          <Button variant="warning" className="w-100" 
          onClick={handlePaypalPayment} 
          disabled={isPaypalPaying || !amount } 
          >
            {isPaypalPaying ? <Spinner animation="border" size="sm" /> : "Pay via PayPal"}
          </Button>
          
        </Form>
      </Modal.Body>
    </Modal>
);
}

export default PaymentPopup
