import React, {useState} from "react"
import {Modal} from "react-bootstrap"
import Login from "./Login"
import SignUp from "./SignUp"
import UserDetails from "./UserDetails"
import VerifyOtp from "./VerifyOtp"

const AuthPopUp = ({setView, view, setAuthorisedUser, refetch}) => {
  const [userDetails, setUserDetails] = useState({
    userName: "",
    email: "",
    trackingCode: "",
    userId: "",
    identifier: ""
  })
  const [signUpFormData, setSignUpFormData] = useState({
    userName: "",
    email: "",
    password: "",
  })
  const [addUserDetailsFormData, setAddUserDetailsFormData] = useState({
    imageUrl: "",
    fullName: "",
    phoneNumber: "",
    dateOfBirth: "",
    referralCode: "",
  })

  return (
    <Modal
      show={view != 0}
      // onHide={() => setView(0)}
      centered
      className="loginContainer"
    >
      {view == 1 ? (
        <Login setView={setView} setAuthorisedUser={setAuthorisedUser} />
      ) : view == 2 ? (
        <SignUp
          setView={setView}
          setUserDetails= {setUserDetails}
          setSignUpFormData={setSignUpFormData}
          signUpFormData={signUpFormData}
        />
      ) : view == 3 ? (
        <UserDetails
          setView={setView}
          userDetails={userDetails}
          setUserDetails= {setUserDetails}
          setAddUserDetailsFormData={setAddUserDetailsFormData}
          addUserDetailsFormData={addUserDetailsFormData}
        />
      ) : view == 4 ? (
        <VerifyOtp
          setView={setView}
          refetch={refetch}
          userDetails={userDetails}
          setUserDetails= {setUserDetails}
          setAuthorisedUser={setAuthorisedUser}
          addUserDetailsFormData={addUserDetailsFormData}
        />
      ) : (
        ""
      )}
    </Modal>
  )
}

export default AuthPopUp
