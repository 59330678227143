import {useState} from "react"
import {Modal, Button, Form} from "react-bootstrap"
import {FaEye, FaEyeSlash} from "react-icons/fa"
import {IoMdClose} from "react-icons/io"
import logo from "../../../../assets/images/lgo.svg"
import {useSignUpMutation} from "../../../../Store/api/ApiSlice"
import {toast} from "react-toastify"
import "./style.css"

const SignUp = ({
  setView,
  setUserDetails,
  signUpFormData,
  setSignUpFormData

}) => {
  const [passwordShown, setPasswordShown] = useState(false)
  const [SignUp, {isSuccess}] = useSignUpMutation()
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown)
  }
  const signUpFunc = (e) => {
    e.preventDefault()
    SignUp({
      userName: signUpFormData?.userName, 
      email: signUpFormData?.email,
      password: signUpFormData?.password
    })
    .unwrap()
    .then((res) => {
        if (res?.data?.user?.trackingCode) {
          setUserDetails({
            userName: res?.data?.user?.userName,
            email: res?.data?.user?.email,
            trackingCode: res?.data?.user?.trackingCode,
            userId: res?.data?.user?._id
          });
          setView(3)
        }
      })
      .catch((e) => {
        toast.error(e?.data?.message ?? "Something went wrong!")
      })
  }
  return (
    <>
      <Modal.Header>
        <h3 className="fs-4 text-white fw-600">Sign Up</h3>
        <IoMdClose
          color="#ffffff"
          fontSize={30}
          onClick={() => setView(0)}
          className="cursor-pointer"
        />
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mb-1 d-flex justify-center">
          <img
            src={logo}
            alt="Compete AE"
            className=""
            style={{width: "150px"}}
          />
        </div>
        <Form>
          <Form.Group className="mb-3" controlid="formUsername">
            <p className="popupLabels pb-1 fw-400 text-white">Username</p>
            <Form.Control
              type="text"
              placeholder="Username"
              value={signUpFormData?.userName}
              onChange={(e)=>setSignUpFormData({...signUpFormData, userName: e.target.value})}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlid="formUsername">
            <p className="popupLabels pb-1 fw-400 text-white">Email</p>
            <Form.Control
              type="text"
              placeholder="Email"
              value={signUpFormData?.email}
              onChange={(e)=>setSignUpFormData({...signUpFormData, email: e.target.value})}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlid="formPassword">
            <p className="popupLabels pb-1 fw-400 text-white">Password *</p>
            <div className="position-relative">
              <Form.Control
                onChange={(e)=>setSignUpFormData({...signUpFormData, password: e.target.value})}
                type={passwordShown ? "text" : "password"}
                value={signUpFormData?.password}
                placeholder="Password"
              />
              <div
                className="position-absolute right-2 top-2"
                onClick={togglePasswordVisibility}
              >
                {passwordShown ? (
                  <FaEyeSlash color="white" size={20} />
                ) : (
                  <FaEye color="white" size={20} />
                )}
              </div>
            </div>
          </Form.Group>
          <p className="popupLabels pt-1 fw-400 text-white text-center px-5">
            By continuing, you agree to the{" "}
            <span className="TandCText">T&C's</span> and the{" "}
            <span className="TandCText">Privacy Policy</span>
            {""}
            of CompeteAE
          </p>
          <div className=" w-100 pt-3 d-flex flex-col items-center">
            <Button
              onClick={signUpFunc}
              variant="primary"
              type="submit"
              className="w-60 fw-700 fs-6 popUpLogBtn"
            >
              Create Account
            </Button>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <p className="text-white h6 fw-500">
          Already have an account? {""}
          <span
            className="fs-6 pl-1 text-white fw--700 no-underline cursor-pointer"
            onClick={() => setView(1)}
          >
            Sign In
          </span>
        </p>
      </Modal.Footer>
    </>
  )
}

export default SignUp
