import React from "react"
import {useState} from "react"
import PhoneInput from "react-phone-number-input"

import "react-phone-number-input/style.css"

const PhoneInputBox = ({value, onChange}) => {
  
  return (
    <div className="">
      <PhoneInput
        international
        defaultCountry={"US"}
        placeholder="Enter phone number "
        className="rounded px-2"
        value={value}
        onChange={onChange}
        style={{
          border: "none",
          height: "40px",
          borderRadius: "3px",
          marginBottom: 0,
          marginTop: "5px",
          backgroundColor: "#ffe5e591",
        }}
      />
    </div>
  )
}

export default PhoneInputBox
