import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://stag.competeae.com/",
    prepareHeaders: (headers, {getState, endpoint}) => {
      // Conditionally add the token for the PaypalPayment endpoint
      if (
        endpoint === "PaypalPayment" ||
        endpoint === "ConfirmPayment" ||
        endpoint == "getWallet" ||
        endpoint == "JoinTeam" ||
        endpoint === "JoinBoth" ||
        endpoint == "JoinSurvivour" ||
        endpoint == "getEventById" ||
        endpoint == "GetEventTeam" ||
        endpoint == "AddToTeam" ||
        endpoint == "getSavedCards" ||  
        endpoint == "deleteCard" ||
        endpoint == "cardPayment"
      ) {
        let loginData = JSON.parse(localStorage?.getItem("loginData"));
        let token = loginData?.data?.accessToken;
        if (token) {
          headers.set("Authorization", `Bearer ${token}`)
        }
      }
      return headers
    },
  }),

  endpoints: (builder) => ({
    getEventList: builder.query({
      query: () => {
        const url = "api/v1/events"
        return {
          url: url,
          method: "GET",
        }
      },
    }),
    getEventById: builder.query({
      query: ({id}) => {
        const url = `api/v1/events/find-one/${id}`
        return {
          url: url,
          method: "GET",
        }
      },
    }),
    signIn: builder.mutation({
      query: (body) => {
        const url = `/api/v1/users/auth/login`
        return {
          url: url,
          method: "POST",
          body: body,
        }
      },
    }),
    signUp: builder.mutation({
      query: (body) => {
        const url = `/api/v1/users/auth/create`
        return {
          url: url,
          method: "POST",
          body: body,
        }
      },
    }),
    addDetails: builder.mutation({
      query: (body) => {
        const url = `/api/v1/users/auth/add-details`
        return {
          url: url,
          method: "POST",
          body: body,
        }
      },
    }),
    imageUpload: builder.mutation({
      query: (payload) => {
        console.log("🚀 ~ payload:", payload)
        return {
          url: `upload`,
          method: "POST",
          body: payload,
        }
      },
      //   invalidatesTags: ["ImageUpload"],
    }),
    VerifyOTP: builder.mutation({
      query: (data) => {
        const url = `api/v1/users/auth/verify-otp`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    CheckUser: builder.mutation({
      query: (data) => {
        const url = `api/v1/user/phone`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    ResendOtp: builder.mutation({
      query: (data) => {
        const url = `users/resend-otp`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    PaypalPayment: builder.mutation({
      query: (data) => {
        const url = `api/v1/payments/paypal/pay`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    ConfirmPayment: builder.mutation({
      query: (data) => {
        const url = `api/v1/payments/paypal/confirm-payment`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    getWallet: builder.query({
      query: (data) => {
        const url = `api/v1/users/wallet`
        return {
          url: url,
          method: "GET",
        }
      },
    }),
    JoinTeam: builder.mutation({
      query: (data) => {
        const url = `api/v1/events/join/team`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    JoinSurvivour: builder.mutation({
      query: (data) => {
        const url = `api/v1/events/join/survivor`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    JoinBoth: builder.mutation({
      query: (data) => {
        const url = `api/v1/events/join/both`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    subscribe: builder.mutation({
      query: (data) => {
        const url = `api/v1/subscribe`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    }),
    GetEventTeam: builder.query({
      query: ({id}) => {
        const url = `api/v1/events/get-event-team/${id}`
        return {
          url: url,
          method: "GET",
        }
      },
    }),
    AddToTeam: builder.query({
      query: (data) => {
        const url = `api/v1/events/add-to-team?eventId=${data?.eventId}&participantId=${data?.participantId}`
        return {
          url: url,
          method: "GET",
        }
      },
    }),
    getSavedCards: builder.query({
      query: () => {
        const url = "api/v1/payments/paypal/card"
        return {
          url: url,
          method: "GET",
        }
      },
    }),
    deleteCard: builder.mutation({
      query: (id) => {
        const url = `api/v1/payments/paypal/card/${id}`
        return {
          url: url,
          method: "DELETE",
        }
      },
    }),
    cardPayment: builder.mutation({
      query: (data) => {
        const url = `api/v1/payments/paypal/card/pay`
        return {
          url: url,
          method: "POST",
          body: data,
        }
      },
    })
    // Add more endpoints here
  }),
})

export const {
  useGetEventListQuery,
  useGetEventByIdQuery,
  useSignInMutation,
  useSignUpMutation,
  useAddDetailsMutation,
  useImageUploadMutation,
  useVerifyOTPMutation,
  useAddToTeamQuery,
  useCheckUserMutation,
  useResendOtpMutation,
  usePaypalPaymentMutation,
  useConfirmPaymentMutation,
  useGetWalletQuery,
  useJoinTeamMutation,
  useJoinSurvivourMutation,
  useJoinBothMutation,
  useGetEventTeamQuery,
  useSubscribeMutation,
  useGetSavedCardsQuery,
  useDeleteCardMutation,
  useCardPaymentMutation,
} = apiSlice
